import React from "react";
import { Layout } from "../../../components";

const Marketing = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Marketing",
        href: pathname,
        lang: "en"
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/oferta/marketing/",
      }}
    >
      <section className="single_offer">
        <div className="single_offer__container">
          <div className="single_offer__header text-center">
            <h1>Marketing</h1>
          </div>
          <div className="single_offer__content">
            <div className="single_offer__marketing">
              <p className="text-center">
                google ads - google ad grants - international marketing campaigns - facebook - pr & copywriting - audits - training courses - recruitment promotion - remarketing banners
              </p>
              <p>
                We create new brands and introduce them to the market. We also help in refreshing and rebranding the existing ones. We develop names, claims and advertising slogans. We design logos and visual identification systems, as well as packaging and product labels for many industries.
              </p>
              <h3>Why us?</h3>
              <p>
                More than a dozen years of experience. Well-thought-out design process. A rich portfolio of Clients and projects. Experience in many industries. Digital and Print competencies
              </p>
              <h3>How we work?</h3>
              <div className="row boxes_row">
                <div className="col-lg-6 col_1">
                  <div className="inner_box">
                    <span>1.</span>
                    <h3>WE LEARN YOUR GOALS</h3>
                    <p>
                      Our promotion strategies are tailored to you. To find solutions for your company, we need to get to know each other. We prefer personal meetings, during which we will ask you about your experiences and business goals. The more we learn about your company and your expectations, the better we will match the perfect solutions to meet them.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col_2">
                  <div className="inner_box">
                    <span>2.</span>
                    <h3>WE ADJUST THE STRATEGY</h3>
                    <p>
                      There are no universal solutions in Internet marketing. What works for online stores will not necessarily work for business promotion of a conference. Based on many years of our own experience, we adjust specific promotion channels and their optimal application that will work for your particular business.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col_3">
                  <div className="inner_box">
                    <span>3.</span>
                    <h3>WE CREATE AND OPTIMIZE</h3>
                    <p>
                      We create campaigns in line with our assumptions. Based on our findings, we select the location and target group, to which we will reach out with our message. However, we do not leave it alone on its own - we optimize it on an ongoing basis, so that it brings the greatest possible results overnight.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col_4">
                  <div className="inner_box">
                    <span>4.</span>
                    <h3>WE ACHIEVE THE SET GOALS</h3>
                    <p>
                      We carefully report on the actions performed, analyze the goals that have been met and set new ones.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Marketing;
